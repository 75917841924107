$color-font: #bababa;

body {
  font-family: "Merriweather Sans", sans-serif !important;
  color: $color-font;
  min-height: 100vh;
  position: relative;
}

.main-container {
  padding: 4rem;
}

.navbar-collapse {
  justify-content: flex-end;
  font-weight: bold;

  ul {
    li {
      margin-right: 2rem;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-font;
}

.top-section {
  margin-bottom: 6rem;
  background-color: #272520;
  padding: 50px;
  width: 100%;

  border: 3px solid rgb(248, 249, 250);

  .info {
    margin-bottom: 3rem;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
  }

  .promo {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .promo-element {
      display: flex;
      flex-direction: column;
      width: 300px;
      text-align: center;
      transition: transform 0.2s; /* Animation */

      img {
        margin: 0 auto;
      }

      span {
        margin-top: 1.5rem;
      }
    }

    .promo-element:hover {
      transform: scale(1.05);
    }

    @media screen and (max-width: 600px) {
      .promo-element {
        margin: 0 auto;
        margin-bottom: 3rem;
      }
    }
  }
}

a {
  color: #1876d2;
}

.page-title {
  font-size: 40px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .hide-mobile {
    display: none !important;
  }
}

.rating {
  font-size: 12px;
  margin-left: 0.5rem;
}

p {
  color: $color-font;
}

table {
  box-shadow: 10px 10px inset #2a2927;
}

.tabell-sort-info {
  display: flex;
}

.table {
  margin-top: 20px;
  color: $color-font;
}

.table thead {
  background: #111;
  color: $color-font;
  border-bottom: 3px solid #6d6c6a;
}

.table tr th,
.table tr td {
  text-align: center;
  border-top: none;
}

.table tr {
  background-color: #383632;
}

.table tr:nth-child(even) {
  background-color: #272521;
}

.table tr:nth-child(1) {
  text-align: left;
}

.table td:first-child {
  text-align: center;
}

li {
  list-style-type: none;
  color: $color-font;
}

ul {
  padding: 0;
}

.margin-top-2 {
  margin-top: 2rem;
}

.regelliste {
  li {
    margin-left: 2rem;
    list-style-type: square;
  }
}

.turneringspoeng-chart {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.antall-spillere-chart {
  padding-bottom: 2rem;
}

.rankingtrend-icon {
  margin-left: 10px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;

  a {
    color: #212529;
  }
  font-size: 12px;
  text-align: center;

  .fa {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .fa:hover {
    text-decoration: none;
  }
}

.poenginfo {
  color: $color-font;
}

.achievement-section {
  h4 {
    margin-left: 1.5rem;
    margin-top: 5rem;
  }

  h4.unlocked {
    color: #0bba23;
  }
}

.xp-until-next {
  text-align: center;
  width: 90%;
}

.achievement-container {
  margin-bottom: 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
}

.xp {
  text-align: center;
  margin-bottom: -10px;
  margin-top: 10px;
}

.achievements {
  margin-top: 3rem;
}

.streakIcon {
  margin-bottom: 10px;
}

.streak-20 {
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: pulsered 2s linear 1s infinite;
  animation: pulsered 2s linear 1s infinite;
  box-shadow: 0 0 8px #eb4034, inset 0 0 8px #eb4034;
}

.lyn,
.streak,
.fischersjakk,
.bullet,
.total,
.deltager,
.partier {
  margin-bottom: -10px;
  display: inline-block;

  h4 {
    color: white;
    display: inline;
  }
}

.partier {
  img {
    background-color: white;
    margin-left: 5px;
    margin-bottom: 5px;
  }
}

.bullet {
  img {
    margin-bottom: 5px;
    margin-left: -5px;
  }
}

.deltager {
  img {
    margin-bottom: 8px;
    margin-left: 3px;
  }
}

.total {
  img {
    margin-left: 2px;
    margin-bottom: 5px;
  }
}

.fischersjakk {
  img {
    margin-left: 2px;
  }
}

.lyn {
  img {
    margin-left: -5px;
  }
}

.perfs {
  margin-bottom: 1rem;
  background-color: #2a2927;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  justify-items: start;
}

.card-body {
  background-color: #2a2927;
  margin-bottom: 1rem;
}

.perfchild {
  padding: 1rem;
  user-select: none;
}

.ikoner {
  display: inline;
  margin-top: 0.5rem;
}

.achievement-element {
  height: 128px;
  margin: 4rem 2rem 2rem 0rem;
}

.achievement-element {
  transition: transform 0.2s; /* Animation */
  width: 100px;
  height: 100px;
  margin-bottom: 30px;
  background-color: #2a2927;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  user-select: none;

  @media screen and (max-width: 420) {
    margin: 0 auto;
    margin-top: 3rem;
  }

  .achievement-description {
    width: 200px;
    font-size: 12px;
    display: none;
    text-align: center;

    background-color: #2a2927;
    padding: 0.4rem;
    border-radius: 3px;

    margin-left: -40px;

    z-index: 99999;
  }

  .achievement-title {
    font-weight: bold;
    position: relative;
    z-index: 10;
    margin-left: -10px;
    width: 120px;
    text-align: center;
    border-radius: 5px;
    margin-top: 15px;
    color: black;
    background-color: #bababa;
  }

  .achieved {
    background-color: #0dba23;
  }

  img {
    position: relative;
    z-index: 8;
    margin-left: 20px;
    margin-top: -30px;
  }

  img.nope {
    filter: gray; /* IE */
    -webkit-filter: grayscale(1); /* Old WebKit */
    -webkit-filter: grayscale(100%); /* New WebKit */
    filter: grayscale(100%); /* Current draft standard */
  }

  #multitalent {
    margin-top: -20px;
  }
}

.achievement-element:hover .achievement-description {
  display: block;
}

.achievement-element:hover {
  transform: scale(1.15);
}

.player-username {
  display: inline-block;
}

.navn {
  display: inline-block;
  color: #bababa;
  padding-left: 1rem;
  font-style: italic;
}

.online {
  color: #0dba23;
}

.wrap-main {
  padding-bottom: 6rem;
}

.live-turneringer {
  border-radius: 5px;
  margin-bottom: 1rem;
  padding: 1rem 1rem 0.5rem 1rem;
  background-color: #2a2927;

  .glowing-circle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #45ff00;
    display: inline-block;
    -webkit-animation: pulse 2s linear 1s infinite;
    animation: pulse 2s linear 1s infinite;
    box-shadow: 0 0 8px #45ff00, inset 0 0 8px #45ff00;
  }

  h3 {
    margin-left: 1rem;
    display: inline-block;
  }
}

img.logo-frontpage {
  width: 500px;
  display: block;
  margin: 0 auto;
  margin-top: -2rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 600px) {
    width: 250px;
  }
}

.kommende-turneringer {
  margin-bottom: 1rem;
  padding: 20px;
  background-color: #272520;
  color: $color-font;
  border: 3px solid rgb(248, 249, 250);
  text-align: center;

  &.alert {
    background-color: #8e2828;
    color: white;
  }
}

.featured-games {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 8px #45ff00, inset 0 0 8px #45ff00;
  }
  50% {
    box-shadow: 0 0 16px #45ff00, inset 0 0 14px #45ff00;
  }
  100% {
    box-shadow: 0 0 8px #45ff00, inset 0 0 8px #45ff00;
  }
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 8px #45ff00, inset 0 0 8px #45ff00;
  }
  50% {
    box-shadow: 0 0 32px #45ff00, inset 0 0 14px #45ff00;
  }
  100% {
    box-shadow: 0 0 8px #45ff00, inset 0 0 8px #45ff00;
  }
}

@keyframes pulsered {
  0% {
    box-shadow: 0 0 8px #eb4034, inset 0 0 8px #eb4034;
  }
  50% {
    box-shadow: 0 0 16px #eb4034, inset 0 0 14px #eb4034;
  }
  100% {
    box-shadow: 0 0 8px #eb4034, inset 0 0 8px #eb4034;
  }
}

@-webkit-keyframes pulsered {
  0% {
    box-shadow: 0 0 8px #eb4034, inset 0 0 8px #eb4034;
  }
  50% {
    box-shadow: 0 0 32px #eb4034, inset 0 0 14px #eb4034;
  }
  100% {
    box-shadow: 0 0 8px #eb4034, inset 0 0 8px #eb4034;
  }
}

// BAR

.skills {
  position: relative;
}

#account-bar-wrapper {
  margin-top: 3rem;
  width: 100%;
  height: 100px;
  text-align: center;
}

#account-bar {
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
}

#account-bar-level {
  color: white;
  font-size: 22px;
  display: inline-block;
}

#account-bar-next-level {
  display: inline-block;
  font-size: 25px;
  color: white;
  margin-left: 1rem;
}

#xp-bar {
  height: 10px;
  width: 75%;
  background: #035;
  margin: 0px 0px 0px 15px;
  display: inline-block;
  vertical-align: middle;
}

#xp-bar-fill {
  height: 100%;
  width: 0.1%;
  background: #3af;
  font-size: 12px;
  line-height: 10px;
  text-align: right;
}

#doit-btn {
  margin: 100px 0px 0px 0px;
}

#xp-increase-fx {
  position: relative;
  display: none;
  height: 100%;
}

.xp-increase-glow1 {
  position: absolute;
  margin: 0px 0px 0px -2px;
  left: 0px;
  top: 0px;
  background: #fff;
  width: 5px;
  height: 100%;
  border-radius: 0px;
  -webkit-filter: blur(2px);
}

.xp-increase-glow2 {
  position: absolute;
  margin: -5px 0px 0px -2px;
  left: 0px;
  top: 0px;
  background: #aaf;
  width: 5px;
  height: 200%;
  border-radius: 0px;
  -webkit-filter: blur(10px);
}

.xp-increase-glow3 {
  position: absolute;
  margin: 0px 0px 0px -5px;
  left: 0px;
  top: 0px;
  background: #fff;
  width: 10px;
  height: 100%;
  border-radius: 5px;
  -webkit-filter: blur(5px);
}

.achievement-page-description {
  margin-top: 2rem;
}
